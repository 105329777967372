import React from "react"
// import { Link } from "gatsby"
  
import Layout from "../components/layout"
import SEO from "../components/seo"

import Registration from "../components/registration"

const IndexPage = () => (
  <Layout>
    <SEO title="Sign Up" />
    <Registration />
  </Layout>
)

export default IndexPage
