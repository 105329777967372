import { Col, Row } from "antd"

import Banners from "./registration/banners"
import Form from "./registration/form"
import React from "react"

class Registration extends React.Component {
  render() {
    return (
      <Row type="flex">
        <Col sm={24} lg={10} xl={12} style={{ backgroundColor: "#fff" }}>
          <Form onStepChange={this.handleFormChange} />
        </Col>
        <Col xs={24} lg={14} xl={12} style={{ backgroundColor: "#95cef7" }}>
          <Banners />
        </Col>
      </Row>
    )
  }
}

export default Registration
