import { Card, Col, Row } from "antd"

import ColumnTitle from "../layout/columnTitle"
import OrangeBanner from "../../images/i-can-read-banner.png"
import React from "react"
import RedBanner from "../../images/pre-reader-banner.png"

const Banners = () => {
  return (
    <Row type="flex" justify="center" gutter={[16, 16]} style={{padding: 30}}>
      <Col span={23}>
        <ColumnTitle text="1 Membership, 2 Apps" align="center" />
        <Card
          bordered={false}
          bodyStyle={{ padding: 0 }}
          cover={<img alt="Clever Kids U: Pre-Reader Banner" src={RedBanner} />}
        ></Card>
      </Col>
      <Col span={23}>
        <Card
          bordered={false}
          bodyStyle={{ padding: 0 }}
          cover={
            <img alt="Clever Kids U: I can Read Banner" src={OrangeBanner} />
          }
        ></Card>
      </Col>
    </Row>
  )
}

export default Banners
